.footer {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;


  @media (min-width:1024px) {
    height: 125px;
  }
}