.main {
  transition: all 0.5s;
  overflow: hidden;
}

.container-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;

  @media (min-width: 768px) {
    padding:  0 40px;
  }
}