@font-face {
  font-family: Gilory;
  src: url(../../fonts/Gilroy-Regular.eot);
}



.navigation {
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  right: 0;
  width: 100%;
  height: 100%;
  top: 120px;
  position: fixed;
  display: block;
  transition: 0.5s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width:768px) {
    top: 200px;
  }
}



.list{
  list-style: none;
  padding-left: 77px;

  @media (min-width:768px) {
    padding-left: 125px;
  }
  @media (min-width:425px) {
    padding-left: 30px;
  }
}

.list__item-link {
  color: black;
  display: block;
  width: max-content;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Gilory, sans-serif;
  font-size: 25px;
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 2px solid #fff;
  transition: 0.5s;

  &:hover {
    border-color: #000;
  }

  @media (min-width:1024px) {
    font-size: 35px;
  }
}