.filter-list {
  list-style: none;
  padding: 0;
  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: 20px;
    cursor: pointer;
    @media (max-width: 768px) {
      margin-right: 20px;
      margin-bottom: 0;
    }
    @media (max-width: 425px) {
      font-size: 11px;
      margin-right: 10px;
    }
    &--active {
      
    }
  }


  &__item-title {
    display: block;
    width: max-content;
    cursor: pointer;
    margin: 0;
    padding: 0 0 20px 0;

    &--active {
      border-bottom: 2px solid black;
    }
  }
  
  &__sublist {
    margin-top: 20px;
    list-style: none;
  }

  &__subitem {
    padding-bottom: 20px;
  }
}