.news {
  padding: 0 10px 40px;
  &__item {
    position: relative;
    margin-bottom: 70px;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: calc(100% - 100px);
      bottom: -30px;
      right: 50px;
      
      background-color: black;

    }
  }
  &__title {
    text-align: center;
    font-size: 30px;
  }

  &__img {
    margin: 0 10px 5px 0;
    height: calc(100% / 3);
    width: calc(100% / 3);
    @media (min-width: 768px) {
      display: block;
      float: none;
     }

     &--float-left {
      float: left;
     }
     &--float-right {
      float: right;
     }
  }

  &__text {
    display: inline;
    @media (min-width: 768px) {
      display: block;
    }

    &--right {
      text-align: left;
    }
  }

  &__text-title {
    text-align: center;
  }

  &__wrap {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }

    &--revert {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  }
}