.contact {
  padding-left: 77px;
  @media (min-width: 768px) {
    padding-left: 0;
  }
  @media (min-width: 1024px) {
    padding-left: 0;
  }

  &__title {
    text-align: center;
  }
  &__link {
    text-decoration: none;
    color: #000;
  }
  
}