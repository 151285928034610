.header{
  padding-bottom: 40px;
  padding: 30px 30px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 0;

    @media (min-width: 768px) {
      padding: 0 30px;
    }

    @media (min-width: 1024px) {
      padding: 0 40px;
    }
  }

  &__img {
    height: 75px;
    min-width: 150px;
    background-image: url(../../logo/svgkorb.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
		transition: all 0.3s;

    @media (min-width: 768px) {
      height: 150px;
      min-width: 300px;
    }

		&:hover{
			transform: scale(1.1);
		}
  }

  &__menu-burger {
    display: block;
    height: 50px;
    width: 50px;
    display: block;
    height: 50px;
    width: 50px;
    background-image: url(./burger-menu.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 500ms;
    &:hover{
      transform: rotate(90deg);
    }
  }
}

.menu-icon {
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;

	.menu-icon__cheeckbox {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		cursor: pointer;
		z-index: 2;
		-webkit-touch-callout: none;
		position: absolute;
		opacity: 0;
	}
	div {
		margin: auto;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 22px;
		height: 12px;
	}
	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background-color: var(--bar-bg, #000);
		border-radius: 1px;
		transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

		&:first-of-type {
			top: 0;
		}
		&:last-of-type {
			bottom: 0;
		}
	}
	&.active,
	.menu-icon__cheeckbox:checked + div {
		span {
			&:first-of-type {
				transform: rotate(45deg);
				top: 5px;
			}
			&:last-of-type {
				transform: rotate(-45deg);
				bottom: 5px;
			}
		}
	}

	&.active:hover span:first-of-type,
	&.active:hover span:last-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
	&:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
		width: 22px;
	}

	&:hover {
		// no need hover effect on mobile.
		@media (min-width: 1024px) {
			span:first-of-type {
				width: 26px;
			}

			span:last-of-type {
				width: 12px;
			}
		}
	}
}
