.works-list {
  &__wrap {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      width: auto;
      padding: 0 45px;
    }
    @media (max-width: 500px) {
      padding: 0 20px;
    }

    .sticky {
      left: 0;
      background: #fff;
      width: 100% !important;
      .works-list__filter {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__filter {
    width: calc(13%);
    @media (max-width: 768px) {
      width: 100%;
      // padding: 0 45px;
    }
    @media (max-width: 500px) {
      // padding: 0 20px;
      font-size: 15px;
    }
  }

  &__main {
    width: calc(87%);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}