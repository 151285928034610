// .app {
//   position: fixed;
//   height: 100%;
//   width: 100%;
// }

.scrollToTop{
  visibility: hidden;
  opacity: 0;
  height: 50px;
  width: 50px;
  background-image: url(./logo/arrowUp.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: rgba($color: #000000, $alpha: 0.0);
  position: fixed;
  bottom: 100px;
  right: 100px;
  transition: 0.7s;

  @media (min-width: 1024px) {
    height: 50px;
    width: 50px;
  }


  &.active {
    opacity: 1;
    transition: 0.5s;
    visibility: visible;
  }
}